import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { memo } from 'react-tracked'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useSnapshot } from 'valtio'
import { scrollbar2Css } from '~/css/scrollbarCss'
import useMedia from '~/hooks/useMedia'
import { AMP_DEFAULT_ARRAY, VOLUME_DEFAULT_ARRAY } from '~/modules/analysis-chart/EmptyData'
import {
  AmplitudeTradeInfo,
  fetchAmpResult,
  fetchVolResult,
  VolumeTradeInfo,
} from '~/modules/analysis-chart/fetchTradeInfo'
import { fill_vertical_cross_center, flex } from '~/modules/AppLayout/FlexGridCss'
import { useAmplitudeStore } from '~/pages/futuresai/amplitude-view/store'
import { useVolumeStore } from '~/pages/futuresai/volume-view/store'
import dayAPI from '~/utils/dayAPI'

export const TradeInfo = memo<ReactProps>(function TradeInfo() {
  const [startDate, setStartDate] = useState(dayAPI())

  useEffect(() => {
    const timer = setInterval(
      (function fetchTradeInfo() {
        fetchAmpResult(startDate).then(_data => {
          if ('status' in _data && _data.status === 'no data') {
            useAmplitudeStore.data = AMP_DEFAULT_ARRAY
          } else if (Array.isArray(_data)) {
            useAmplitudeStore.data = _data
          }
        })

        fetchVolResult(startDate).then(_data => {
          if ('status' in _data && _data.status === 'no data') {
            useVolumeStore.data = VOLUME_DEFAULT_ARRAY
          } else if (Array.isArray(_data)) {
            useVolumeStore.data = _data
          }
        })

        return fetchTradeInfo
      })(),
      15000,
    )
    return () => clearInterval(timer)
  }, [startDate])

  const useAmplitudeState = useSnapshot(useAmplitudeStore)
  const useVolumeState = useSnapshot(useVolumeStore)

  return (
    <div
      css={css`
        ${scrollbar2Css};
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 4px;
        gap: 4px;
      `}
    >
      <div css={titleCss}>{startDate.format('YYYY/MM/DD')} 當日振幅</div>
      <DataBar
        data={useAmplitudeState.data as AmplitudeTradeInfo[]}
        dataXAxis={'Time'}
        dataY1Axis={'CurrentAmp'}
        dataY2Axis={'AvgCurrentAmp'}
        name1={'當日振幅'}
        name2={'平均振幅(20日平均值)'}
        fill1Color='#ff9933'
        fill2Color='#787878'
      />
      <div css={titleCss}>{startDate.format('YYYY/MM/DD')} 當日成交量</div>
      <DataBar
        data={useVolumeState.data as VolumeTradeInfo[]}
        dataXAxis={'Time'}
        dataY1Axis={'CurrentVol'}
        dataY2Axis={'AvgCurrentVol'}
        name1={'當日成交量'}
        name2={'平均成交量(20日平均值)'}
        fill1Color='#ff3333'
        fill2Color='#787878'
      />
    </div>
  )
})

const DataBar = memo(function DataBar(options: {
  data: (AmplitudeTradeInfo | VolumeTradeInfo)[]
  fill1Color: string
  fill2Color: string
  dataXAxis: string
  dataY1Axis: string
  dataY2Axis: string
  name1: string
  name2: string
}) {
  const isPc = useMedia()
  return (
    <div css={chartContainerCss}>
      <ResponsiveContainer
        width='100%'
        height={isPc ? 324 : 180}
      >
        <BarChart
          data={options.data}
          margin={{
            top: 30,
            right: 20,
            left: 10,
            bottom: 0,
          }}
        >
          <CartesianGrid
            strokeDasharray='3 3'
            stroke='#444444'
            fill='#2a2a2a'
          />
          <XAxis
            dataKey={options.dataXAxis}
            stroke='#ffffff'
          />
          <YAxis stroke='#ffffff' />
          <Tooltip />
          <Legend />
          <Bar
            dataKey={options.dataY1Axis}
            fill={options.fill1Color}
            name={options.name1}
          />
          <Bar
            dataKey={options.dataY2Axis}
            fill={options.fill2Color}
            name={options.name2}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
})

const chartContainerCss = css`
  ${flex.h.allCenter};
  width: 100%;
  padding: 4px 8px;
  border-radius: 4px;
  color: ${'#ffffff'};
  background-color: ${'#212121'};
`
const titleCss = css`
  ${flex.h.allCenter};
  height: 32px;
  width: 200px;
  border-radius: 4px;
  background-color: #4b5d93;
`
