import { css } from '@emotion/react'
import { createDesktopCss } from '~/css/createDesktopCss'
import { fontWeight600 } from '~/css/font'
import { globalBlue, globalGrey } from '~/modules/AppLayout/Colors'
import { ai, flex } from '~/modules/AppLayout/FlexGridCss'

export const itemTitleCss = (backgroundColor?: string) => css`
  align-self: flex-start;
  ${fontWeight600};
  font-size: 1rem;
  background: ${backgroundColor ?? globalBlue.b400};
  color: #ffffff;
  border-radius: 4px;
  padding: 4px 8px;
`

export const tradeFreqKeyCss = css`
  font-size: 16px;
`

export const totalProfitCss = css`
  ${fontWeight600};
  font-size: 20px;
`

export const tradeFreqValueCss = css`
  ${fontWeight600};
  font-size: 20px;
`

export const narrowColumnCss = css`
  ${flex.v.allCenter};
  width: 100%;
  margin: 8px;
  gap: 8px;

  ${createDesktopCss(css`
    ${flex.h.crossCenter};
    width: 1080px;
    gap: 16px;
    ${ai.stretch};
  `)}
  margin-top: 16px;
`

export const columnCss = css`
  ${flex.v.allCenter};
  width: 100%;
  height: 1080px;
  margin: 8px;
  gap: 8px;

  ${createDesktopCss(css`
    ${flex.h.default};
    width: 1080px;
    height: 480px;
    gap: 16px;
  `)}
  margin-top: 16px;
`

export const sectionCss = css`
  ${flex.v.allCenter};
  width: calc(100vw - 32px);
  background: #ffffff;
  padding: 8px 16px;
  border-radius: 8px;

  ${createDesktopCss(css`
    ${flex.v.allCenter};
    width: 50%;
    height: 100%;
  `)}
`

export const chartCss = css`
  width: 100%;
  height: 480px;

  ${createDesktopCss(css`
    width: 100%;
    height: 100%;
    flex: 1;
    margin-top: 8px;
  `)}
`

export const top3ColumnCss = css`
  ${columnCss};
  width: 100%;
  height: 400px;

  ${createDesktopCss(css`
    height: auto;
  `)}
`

export const top3SectionCss = css`
  ${flex.v.allCenter};
  width: calc(100vw - 32px);
  background: #ffffff;
  padding: 8px 16px;
  border-radius: 8px;

  ${createDesktopCss(css`
    ${flex.v.default};
    width: 50%;
    height: 200px;
  `)}
  flex: 1;
`

export const top3KeyValueCss = css`
  ${flex.h.crossCenter};
  width: 100%;
  height: 48px;
  margin: 0 16px;
  border-bottom: 1px solid ${globalGrey.g200};
  &:last-of-type {
    border-bottom: 0;
  }
`

export const top3KeyCss = css`
  font-size: 18px;
  width: 120px;

  ${createDesktopCss(css`
    margin: 0;
    font-size: 20px;
    width: 180px;
  `)}
`
export const top3ValueCss = css`
  font-size: 22px;

  ${createDesktopCss(css`
    margin: 0;
    font-size: 24px;
    ${fontWeight600};
    margin-left: 24px;
  `)}
`
